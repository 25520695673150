<template>
  <div>  <el-row :gutter="10">
           <el-col
               :xs="24"
               :sm="24"
               :md="24"
               :lg="24"
               :xl="24"
           >
             <div class="container-right">
               <el-card class="sidebar-card">
                 <!-- 搜索栏 -->
                 <div class="search-container">
                   <el-input
                     placeholder="搜索游戏..."
                     v-model="searchQuery"
                     prefix-icon="el-icon-search"
                     clearable
                     @input="handleSearch"
                   ></el-input>
                 </div>

                 <!-- 分类标签 -->
                 <div class="category-tabs">
                   <el-tabs v-model="activeCategory" @tab-click="handleCategoryChange">
                     <el-tab-pane label="热门推荐" name="hot"></el-tab-pane>
                     <el-tab-pane label="新品发售" name="new"></el-tab-pane>
                     <el-tab-pane label="特惠折扣" name="discount"></el-tab-pane>
                   </el-tabs>
                 </div>

                 <!-- 游戏列表 -->
                 <div v-if="filteredGameList.length > 0" class="game-list">
                   <div
                     class="game-item"
                     @mouseover="mouseover(index)"
                     @mouseout="mouseout(index)"
                     v-for="(item, index) of filteredGameList"
                     :key="index"
                     v-show="item.imgsrc"
                     @click="viewGameDetails(item)"
                   >
                     <div class="game-image">
                       <img :class="item.srcstyle" :src="item.imgsrc"/>
                       <div class="game-rank" v-if="activeCategory === 'hot'">{{index + 1}}</div>
                     </div>
                     <div class="game-info">
                       <div class="game-name" :class="item.rihtTitle">{{ item.name }}</div>
                       <div v-show="item.ifhiddentext" class="game-desc">
                         <span class="game-review">{{ item.recentreviews }}</span>
                         <span class="game-tags" v-if="item.tags">{{ item.tags.slice(0, 2).join(', ') }}</span>
                         <span class="game-price" v-if="item.price">{{ formatPrice(item.price) }}</span>
                       </div>
                     </div>
                   </div>
                 </div>

                 <!-- 无数据提示 -->
                 <div v-else class="no-data">
                   <i class="el-icon-warning-outline"></i>
                   <span>暂无相关游戏</span>
                 </div>

                 <!-- 查看更多 -->
                 <div class="view-more">
                   <el-button type="text" icon="el-icon-more" @click="viewMoreGames">查看更多</el-button>
                 </div>
               </el-card>

               <!-- 精选内容卡片 -->
               <el-card class="featured-card" v-if="featuredGame">
                 <div class="featured-title">精选推荐</div>
                 <div class="featured-content" @click="viewGameDetails(featuredGame)">
                   <img :src="featuredGame.imgsrc" class="featured-image"/>
                   <div class="featured-overlay">
                     <h3>{{ featuredGame.name }}</h3>
                     <div class="featured-info">
                       <span v-if="featuredGame.releaseDate">发售日期: {{ featuredGame.releaseDate }}</span>
                       <span v-if="featuredGame.price">价格: {{ formatPrice(featuredGame.price) }}</span>
                     </div>
                     <el-button size="small" type="primary">查看详情</el-button>
                   </div>
                 </div>
               </el-card>
             </div>
           </el-col>
         </el-row>

         <!-- 游戏详情弹窗 -->
         <el-dialog
           title="游戏详情"
           :visible.sync="dialogVisible"
           width="50%"
           :before-close="handleClose"
         >
           <div v-if="selectedGame" class="game-details">
             <div class="game-header">
               <img :src="selectedGame.imgsrc" class="detail-image"/>
               <div class="detail-info">
                 <h2>{{ selectedGame.name }}</h2>
                 <div class="detail-meta">
                   <span class="review-tag" :class="getReviewClass(selectedGame.recentreviews)">{{ selectedGame.recentreviews }}</span>
                   <span class="release-date" v-if="selectedGame.releaseDate">{{ selectedGame.releaseDate }}</span>
                 </div>
                 <div class="detail-tags" v-if="selectedGame.tags">
                   <el-tag v-for="(tag, i) in selectedGame.tags" :key="i" size="mini" effect="plain">{{ tag }}</el-tag>
                 </div>
                 <div class="detail-price" v-if="selectedGame.price">
                   {{ formatPrice(selectedGame.price) }}
                 </div>
               </div>
             </div>
             <div class="game-description">
               <p>{{ selectedGame.description || '暂无详细描述' }}</p>
             </div>
             <div class="action-buttons">
               <el-button type="primary" @click="changelink(selectedGame.link)">访问商店页面</el-button>
               <el-button type="success" @click="addToWishlist(selectedGame)">加入愿望单</el-button>
             </div>
           </div>
           <span slot="footer" class="dialog-footer">
             <el-button @click="dialogVisible = false">关闭</el-button>
           </span>
         </el-dialog></div>

</template>

<script>
import axios from "axios";

export default {
  name: "ranking",
  data() {
    return {
      index: "0",
      gameList: [],
      filteredGameList: [],
      searchQuery: "",
      activeCategory: "hot",
      dialogVisible: false,
      selectedGame: null,
      featuredGame: null
    };
  },
  methods: {
    changeLeftItem(index) {
      sessionStorage.setItem("passage", index);
      this.$router.push({
        name: "information"
      });
    },

    async gameget() {
      try {
        const res = await axios.get(this.$globle.allurl + "getHotgame");
        this.gameList = res.data;
        
        // 模拟添加更多游戏数据
        this.gameList = this.gameList.map(game => {
          return {
            ...game,
            imgsrc: "https://media.st.dl.eccdnx.com/steam/apps/" + game.gameid + "/header.jpg",
            srcstyle: "crcnoselected",
            rihtTitle: "titlenoselected",
            ifhiddentext: false,
            tags: this.generateRandomTags(),
            price: Math.floor(Math.random() * 300) + 30,
            releaseDate: this.generateRandomDate(),
            description: "这是一款精彩的游戏，提供了令人身临其境的游戏体验和吸引人的故事情节。游戏玩法丰富多样，画面精美，音效逼真。"
          };
        });
        
        this.filteredGameList = [...this.gameList];
        
        // 设置精选游戏
        if (this.gameList.length > 0) {
          this.featuredGame = this.gameList[Math.floor(Math.random() * this.gameList.length)];
        }
      } catch (error) {
        console.error("获取游戏数据失败:", error);
        this.$message.error("获取游戏数据失败，请稍后重试");
      }
    },
    
    generateRandomTags() {
      const allTags = ["动作", "冒险", "策略", "角色扮演", "休闲", "模拟", "体育", "竞速", "独立", "多人", "开放世界", "解谜"];
      const numTags = Math.floor(Math.random() * 3) + 1;
      const selectedTags = [];
      
      for (let i = 0; i < numTags; i++) {
        const randomIndex = Math.floor(Math.random() * allTags.length);
        selectedTags.push(allTags[randomIndex]);
        allTags.splice(randomIndex, 1);
      }
      
      return selectedTags;
    },
    
    generateRandomDate() {
      const year = 2018 + Math.floor(Math.random() * 5);
      const month = Math.floor(Math.random() * 12) + 1;
      const day = Math.floor(Math.random() * 28) + 1;
      return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    },
    
    changelink(event) {
      window.open(event);
    },

    mouseout(index) {
      this.filteredGameList[index].ifhiddentext = false;
      this.filteredGameList[index].srcstyle = "crcnoselected";
      this.filteredGameList[index].rihtTitle = "titlenoselected";
    },
    
    mouseover(index) {
      this.index = index;
      for (let i in this.filteredGameList) {
        this.filteredGameList[i].ifhiddentext = false;
        this.filteredGameList[i].srcstyle = "crcnoselected";
        this.filteredGameList[i].rihtTitle = "titlenoselected";
      }
      this.filteredGameList[index].ifhiddentext = true;
      this.filteredGameList[index].srcstyle = "crcselected";
      this.filteredGameList[index].rihtTitle = "titleselected";
    },
    
    handleSearch() {
      if (!this.searchQuery) {
        this.filteredGameList = [...this.gameList];
        return;
      }
      
      this.filteredGameList = this.gameList.filter(game => 
        game.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    
    handleCategoryChange() {
      // 在实际应用中，这里可以根据不同分类请求不同数据
      // 这里做简化处理
      if (this.activeCategory === 'hot') {
        this.filteredGameList = [...this.gameList];
      } else if (this.activeCategory === 'new') {
        // 按发售日期排序，最新的在前面
        this.filteredGameList = [...this.gameList].sort((a, b) => 
          new Date(b.releaseDate) - new Date(a.releaseDate)
        );
      } else if (this.activeCategory === 'discount') {
        // 随机选择一些打折的游戏
        this.filteredGameList = this.gameList.filter(() => Math.random() > 0.5);
      }
    },
    
    viewGameDetails(game) {
      this.selectedGame = game;
      this.dialogVisible = true;
    },
    
    handleClose() {
      this.dialogVisible = false;
      this.selectedGame = null;
    },
    
    formatPrice(price) {
      return `¥${price.toFixed(2)}`;
    },
    
    getReviewClass(review) {
      if (review && review.includes('好评')) {
        return 'positive-review';
      } else if (review && review.includes('差评')) {
        return 'negative-review';
      }
      return 'neutral-review';
    },
    
    viewMoreGames() {
      this.$message.info('查看更多游戏功能正在开发中');
    },
    
    addToWishlist(game) {
      this.$message.success(`已将《${game.name}》加入愿望单`);
    }
  },
  mounted() {
    this.gameget();
  },
};
</script>

<style scoped>
.container-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sidebar-card {
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.sidebar-card:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.search-container {
  margin-bottom: 15px;
}

.category-tabs {
  margin-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
}

.game-list {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 8px;
}

.game-item {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.game-item:hover {
  background-color: #f9f9f9;
  transform: translateY(-2px);
}

.game-image {
  position: relative;
  margin-right: 15px;
}

.game-rank {
  position: absolute;
  top: -8px;
  left: -8px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #409EFF;
  color: white;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-item:nth-child(1) .game-rank {
  background-color: #F56C6C;
}

.game-item:nth-child(2) .game-rank {
  background-color: #E6A23C;
}

.game-item:nth-child(3) .game-rank {
  background-color: #67C23A;
}

.game-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.game-name {
  font-size: 15px;
  color: #303133;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.game-desc {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.game-review {
  font-size: 12px;
  color: #67C23A;
}

.game-tags {
  font-size: 12px;
  color: #909399;
}

.game-price {
  font-size: 14px;
  color: #F56C6C;
  font-weight: bold;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  color: #909399;
}

.no-data i {
  font-size: 30px;
  margin-bottom: 10px;
}

.view-more {
  text-align: center;
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px dashed #ebeef5;
}

.featured-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.featured-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #303133;
}

.featured-content {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.featured-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.featured-content:hover .featured-image {
  transform: scale(1.05);
}

.featured-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  color: white;
  transition: all 0.3s ease;
}

.featured-content:hover .featured-overlay {
  padding-bottom: 25px;
}

.featured-overlay h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
}

.featured-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
  margin-bottom: 10px;
}

/* 游戏详情弹窗样式 */
.game-details {
  padding: 10px;
}

.game-header {
  display: flex;
  margin-bottom: 20px;
}

.detail-image {
  width: 300px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.detail-info {
  flex: 1;
}

.detail-info h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #303133;
}

.detail-meta {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.review-tag {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.positive-review {
  background-color: #f0f9eb;
  color: #67C23A;
}

.negative-review {
  background-color: #fef0f0;
  color: #F56C6C;
}

.neutral-review {
  background-color: #f4f4f5;
  color: #909399;
}

.release-date {
  color: #606266;
  font-size: 14px;
}

.detail-tags {
  margin-bottom: 15px;
}

.detail-price {
  font-size: 20px;
  color: #F56C6C;
  font-weight: bold;
}

.game-description {
  margin-bottom: 20px;
  line-height: 1.6;
  color: #606266;
}

.action-buttons {
  display: flex;
  gap: 15px;
}

/* 滚动条样式 */
.game-list::-webkit-scrollbar {
  width: 6px;
}

.game-list::-webkit-scrollbar-thumb {
  background-color: #dcdfe6;
  border-radius: 3px;
}

.game-list::-webkit-scrollbar-track {
  background-color: #f2f6fc;
}

/* 原有类名保留并优化 */
.crcnoselected {
  width: 60px;
  height: 30px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.crcselected {
  width: 100px;
  height: 50px;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.titlenoselected {
  color: #303133;
  transition: color 0.3s ease;
}

.titleselected {
  color: #409EFF;
  font-weight: bold;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .game-header {
    flex-direction: column;
  }
  
  .detail-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
</style>
