<template>
  <div>
    <skeleton type="article-list" v-if="load" />
    <!-- 左侧内容显示 -->
    <el-row :gutter="20"
            class="articlehot">
      <div style="max-width: 1200px;margin: 0 auto;">
        <el-col :span="6"
                v-for="(o, index) in gameData"
                :key="index">
          <el-card class="titlecard"
                   :body-style="{padding: '0px'}"
                   shadow="never">
            <img :src="o.picture"
                 class="image">
            <div class="card-content">
						<span class="card-title"
                  @click="changeLeftItem(o.id)">{{ o.title }}</span>
              <div class="meta-info">
                <div>
								<span class="tags">
									<el-tag v-for="(tag, i) in o.tags"
                          :key="i"
                          size="small"
                          type="info">{{
                      tag
                    }}</el-tag>
								</span>
                </div>
                <div>
                  <div>
                    <span class="author">作者: {{ o.nickname }}</span>
                  </div>
                  <div>
                    <time class="author">{{ o.create_time }}</time>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </div>
    </el-row>

    <el-row style="margin: 20px 126px;">
      <div style="max-width: 1200px;margin: 0 auto;">
        <el-col class="data"
                :span="17">
          <el-card>
            <div class="content">
              <!-- 左侧部分 -->
              <el-card class="content-left"
                       v-for="(item) of articleData"
                       :key="item.id"
                       style="margin-bottom: 13px;"
                       shadow="never"
                       @click.native="changeLeftItem(item.id)">

                <!-- 文章内容区域 -->
                <div style="display: flex; justify-content: space-between;">

                  <!-- 文章图片 -->
                  <div v-if="item.picture"
                       style="margin-right: 15px;width: 190px;height: 118px">
                    <img :src="item.picture"
                         class="articleimage"
                         style="max-width: 100%; border-radius: 8px;">
                  </div>

                  <!-- 文章文字内容 -->
                  <div style="flex: 3;">
                    <div>
                      <img class="article-card-top-img"
                           src="https://cdn.cnbj1.fds.api.mi-img.com/aife/technology-platform-fe/preview/dist/static/commandImg.99adc700.svg"
                           alt="Article Top Image">
                      <span class="articletitle"
                            style="cursor:pointer;">{{ item.title }}</span>
                    </div>

                    <!-- 文章摘要 -->
                    <div class="articlesum"
                         style="margin-top: 10px;">{{ item.summary }}
                    </div>

                    <!-- 底部统计区 -->
                    <div class="bottom"
                         style="margin-top: 20px;">
                      <div style="display: flex;justify-content: space-between;font-size: 15px;">
											<span>
												<strong>作者:</strong> {{ item.username }}
											</span>
                        <span style="margin-left: 15px;">
												<strong>发布:</strong> {{ item.create_time }}
											</span>
                        <div style="display: flex;margin: 6px 0px -10px 21px;">
                          <div class="tags">
                            <i class="el-icon-view"></i>
                            <span class="cdc-icon__number">{{ item.viewCount }}</span>
                          </div>
                          <!-- 评论计数 -->
                          <div class="tags">
                            <i class="el-icon-chat-line-round"></i>
                            <span class="cdc-icon__number">{{ item.comments }}</span>
                          </div>
                          <!-- 点赞计数 -->
                          <div class="tags">
                            <i class="el-icon-caret-top"></i>
                            <span class="cdc-icon__number">{{ item.likes }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-card>

            </div>
            <el-pagination @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           :page-sizes="[5, 10, 20]"
                           :page-size="pageSize"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="total">
            </el-pagination>
          </el-card>


          <!-- 右侧部分 -->
        </el-col>

        <el-col :span="6"
                style="float: right">
          <Swiperlist/>
        </el-col>
      </div>

    </el-row>

    <div class="floating-sidebar">
      <el-tooltip class="item" effect="dark" content="发布文章" placement="left">
        <div @click="push('createarticle')" style="cursor: pointer">
          <i class="el-icon-circle-plus-outline"></i>
        </div>
      </el-tooltip>
    </div>
  </div>
  <!--      <Swiperlist/>-->
</template>

<script>
// import Swiperlist from "@/components/Swiperlist/index.vue";
import Swiperlist from "@/components/Swiperlist/index.vue";
import api from "@/axios/api";
import {ARTICLE_API} from "@/api/article-api";
import Skeleton from "@/components/Skeleton/index.vue";


export default {
  components: {
    Skeleton,
    Swiperlist
    // Swiperlist
  },
  data() {
    return {
      load: false,
      currentDate: new Date().toLocaleDateString(),
      gameData: [],

      articleData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    gethotarticle() {
      api.get(ARTICLE_API.GET_HOTARTICLE).then((res) => {
        this.gameData = res.result;
      })
    },

    articalget() {
      this.load = true;
      api.get(ARTICLE_API.GET_PAGEARTICLE, {
        params: {
          pagenum: this.currentPage,
          pagesize: this.pageSize,
        }
      }).then((res) => {
        this.load = false
        this.articleData = res.result.records
        this.total = res.result.total
      })
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.articalget()
    },

    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.articalget()
    },
    push(index) {
      this.$router.push({
        name: index,
      });
    },
    changeLeftItem(index) {
      this.$router.push({
        name: "information",
        query: {
          id: index,
        },
      });
    },
  },
  created() {
    this.articalget()
    this.gethotarticle()
  }
};
</script>

<style scoped>
.floating-sidebar {
  font-size: 50px;
  position: fixed;
  bottom: 25%;
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  z-index: 99999; /* 确保浮动的悬浮栏始终在最上层 */
}

.articlehot {
  background: linear-gradient(1deg, #2196F3, #BBDEFB);
}

.image {
  width: 288px;
  height: 162px;
}

.titlecard {
  border-radius: 20px;
  margin: 20px;
  transition: transform 0.3s ease;
  box-shadow: 0 10px 6px 0 rgba(0, 0, 0, .2);
}

.titlecard:hover {
  transform: translateY(-5px);
}

.card-title {
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap; /* 防止换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
  display: block; /* 让它成为块级元素，以便应用宽度限制 */
  width: 100%; /* 宽度设为100% */
}

.titlecard:hover .card-title {
  color: orange;
  cursor: pointer;
}

.card-content {
  padding: 20px;
}

.author {
  font-size: 14px;
  color: #a2a2a2;
}


.content-left {
  text-align: left;
  border: none;
  border-bottom: 1px solid #EBEEF5;
}

.content-left:hover {
  transform: scale(1.01);
}

.content-left:hover .articletitle {
  color: orange;
  cursor: pointer;
}

.articletitle {
  font-weight: 500;
  font-size: 18px;
}

.articlesum {
  font-size: 14px;
  line-height: 22px;
  color: #515767;
  min-height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  margin: 10px;
}

.botom {
  font-size: 12px;
  display: flex;
}

.read-comment-praise {
  height: 16px;
  width: 16px;
}

.tags {
  margin-right: 20px;
}

.articleimage {
  height: 100px;
  width: 190px;
}

</style>
